<script setup>
import { useStore } from '@/store'
import { computed } from 'vue'

const store = useStore()

const authAccessLevel = computed(() => store.state.auth.accessLevel)

/** @type {TabItem[]} */
const items = [
  {
    label: 'Templates',
    path: { name: 'preventive-maintenance.template' },
    hidden: authAccessLevel.value < 5,
  },
  {
    label: 'Check List',
    path: { name: 'preventive-maintenance.list' },
  },
]

/** @typedef {import('@/components/global/BaseNavigationTabs.vue').BaseNavigationTabItem} TabItem */
</script>
<template>
  <div>
    <base-navigation-tabs :items="items"></base-navigation-tabs>
    <router-view></router-view>
  </div>
</template>
